import React from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";

function modulos() {
  return (
    <>
      <Container fluid>
      <div class="row d-flex justify-content-center">
      <Col lg="10" md="12" sm="12">
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Módulos del Atlas de Riesgos</Card.Title>
                <p className="card-category">
                  Los módulos permiten visualizar las capas del Atlas de Riesgos
                  e interactuar con la misma a través de herramientas
                </p>
              </Card.Header>
              <Card.Body className="all-icons">
                <Row>
                  <Col className="font-icon-list" lg="3" md="3" sm="4" xs="6">

                    <div className="font-icon-detail">
                      <i className="nc-icon"></i>
                      <div>
                        <a href="https://www.atlas.cdmx.gob.mx/indicadores/"
                        target="_blank"className="card-category">
                        <img className="d-block w-100"
                          src={require("assets/img/ageb.png")
                          .default} style={{'weight':"30px"}}  />
                        </a>
                      </div>
                      <p>Indicadores a nivel AGEB</p>
                    </div>
                  </Col>
                  <Col className="font-icon-list" lg="3" md="3" sm="4" xs="6">

                    <div className="font-icon-detail">
                      <i className="nc-icon"></i>
                      <div>
                        <a href="https://www.atlas.cdmx.gob.mx/analisisn3/"
                        target="_blank"className="card-category">
                        <img className="d-block w-100"
                          src={require("assets/img/analisism.png")
                          .default} style={{'weight':"50px"}}  />
                        </a>
                      </div>
                      <p>Análisis de peligros y exposición </p>
                    </div>
                  </Col>
                  <Col className="font-icon-list" lg="3" md="3" sm="4" xs="6">

                    <div className="font-icon-detail">
                      <i className="nc-icon"></i>
                      <div>
                        <a href="https://www.atlas.cdmx.gob.mx/RUSE/"
                        target="_blank"className="card-category">
                        <img className="d-block w-100"
                          src={require("assets/img/reuse.png")
                          .default} style={{'weight':"50px"}}  />
                        </a>
                      </div>
                      <p>REUSE</p>
                    </div>
                  </Col>

                  <Col className="font-icon-list" lg="3" md="3" sm="4" xs="6">

                    <div className="font-icon-detail">
                      <i className="nc-icon"></i>
                      <div>
                        <a href="https://www.atlas.cdmx.gob.mx/GRE/"
                        target="_blank"className="card-category">
                        <img className="d-block w-100"
                          src={require("assets/img/GRE.png")
                          .default} style={{'weight':"50px"}}  />
                        </a>
                      </div>
                      <p>Emergencias Químicas</p>
                    </div>
                  </Col>

                  <Col className="font-icon-list" lg="3" md="3" sm="4" xs="6">

                    <div className="font-icon-detail">
                      <i className="nc-icon"></i>
                      <div>
                        <a href="https://www.atlas.cdmx.gob.mx/genero.html"
                        target="_blank"className="card-category">
                        <img className="d-block w-100"
                          src={require("assets/img/genero.png")
                          .default} style={{'weight':"50px"}}  />
                        </a>
                      </div>
                      <p>Indicadores de género</p>
                    </div>
                  </Col>
                  <Col className="font-icon-list" lg="3" md="3" sm="4" xs="6">
                    <div className="font-icon-detail">
                      <i className="nc-icon"></i>
                      <div>
                        <a href="https://www.atlas.cdmx.gob.mx/mercados_html/"
                        target="_blank"className="card-category">
                        <img className="d-block w-100"
                          src={require("assets/img/mercados.png")
                          .default} style={{'weight':"50px"}}  />
                        </a>
                      </div>
                      <p>Revisión de mercados</p>
                    </div>
                  </Col>
                  <Col className="font-icon-list" lg="3" md="3" sm="4" xs="6">
                    <div className="font-icon-detail">
                      <i className="nc-icon"></i>
                      <div>
                        <a href="https://www.atlas.cdmx.gob.mx/monitoreo.html"
                        target="_blank"className="card-category">
                        <img className="d-block w-100"
                          src={require("assets/img/monitoreo.png")
                          .default} style={{'weight':"50px"}}  />
                        </a>
                      </div>
                      <p>Monitoreo de fenómenos</p>
                    </div>
                  </Col>


                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        </Col>
            </div>
      </Container>
    </>
  );
}

export default modulos;
